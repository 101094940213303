import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Layout from "../components/Layout";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const tagHeader = tag.toUpperCase();

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`}>
            <meta
              name="description"
              content={`Welcome to Pineapple Money! Here we talk about personal finance, and how to make money. This page shows all the articles tagged with ${tag}.`}
            />
          </Helmet>
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: "6rem" }}
              >
                <h1 className="title is-size-4 is-bold-light">
                  {tagHeader} ARTICLES
                </h1>

                <div className="columns is-multiline">
                  {posts &&
                    posts.map(({ node: post }) => (
                      <div className="is-parent column is-6" key={post.id}>
                        <Link
                          className="title has-text-primary is-size-4"
                          to={post.fields.slug}
                        >
                          <article
                            className={`blog-list-item tile is-child box notification ${
                              post.frontmatter.featuredpost ? "is-featured" : ""
                            }`}
                          >
                            {post.frontmatter.featuredimage ? (
                              <div className="featured-thumbnail">
                                <PreviewCompatibleImage
                                  imageInfo={{
                                    image: post.frontmatter.featuredimage,
                                    alt: `featured image thumbnail for post ${post.title}`,
                                  }}
                                />
                              </div>
                            ) : null}
                            <p className="post-meta">
                              {post.frontmatter.title}
                            </p>
                          </article>
                        </Link>
                      </div>
                    ))}
                </div>
                <Link to="/tags/">Browse all tags</Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
